$('#whatsapp').on('submit', function(e) {
    e.preventDefault();
    if (noValidate($(this))) {
        return
	}
	var fd = new FormData(this);
	new URL(window.location.href).searchParams.forEach(function(value, key) {
		fd.append(key, value);
	});
    $.ajax({
        url: './whatsapp.php',
        type: 'POST',
        contentType: false,
        processData: false,
        data: fd,
        success: function(){
            try {
              yaCounter61923046.reachGoal('WHATSAPP-SENDFORM');
            } catch(e) {
              console.log(e);
            }
			closeModal();
			showModal('thanks-modal');
        },
	});
	fd.append('whatsapp', '1');
	$.ajax({
		url: './action.php',
		type: 'POST',
		contentType: false,
		processData: false,
		data: fd,
	});
});