(function initTehnikaQuiz() {
	let  number = 0;
	let  maxNumber = $(".tehnika-quiz-modal .test-item").length - 2;
	let  $element = $(".tehnika-quiz-modal .test-item").find("input, select, textarea");
	let  $elementRadio = $(".tehnika-quiz-modal .test-item").not('.final, .no-focus').find("input[type='radio'], input[type='checkbox'] ");
	let  btnNext = $('.tehnika-quiz-modal .test-next');
	let  isValid;
	let  dataBlock;
	let  activeSlede = [];
	let  testTextNum = maxNumber;
	let  testText = $('.tehnika-quiz-modal #tqw-title');

	for (let  i = 0; i < maxNumber; i++) {
		activeSlede[i] = false;
	}
	testText.text(testTextNum + ' вопросов');
	$element.on('change input', function (e) {
		let  value = $(this).val().trim();

		isValid = value !== "";
		btnActive(!isValid);

	});

	function btnActive(isValid) {
		if (number === 0) {
			// btnPrev.prop('disabled', 'false');
			btnNext.prop('disabled', isValid);
		} else {
			// btnPrev.prop('disabled', false);
			if (activeSlede[number] === true || isValid === false) {
				btnNext.prop('disabled', false);
			} else {
				btnNext.prop('disabled', true);
			}

		}

	}

	// sidebar

	let  $barLevel = 100 / (maxNumber + 1);
	let  $barWidth = 0;
	// money
	let  slideMoney = Math.floor(2000 / (maxNumber + 3));
	let  sumMoney = slideMoney;

	function progress() {
		$(".tehnika-quiz-modal .progress-bar__line").css({ "width": $barWidth + '%' });

		moneyAnim(".tehnika-quiz-modal .proc-progres", Math.floor($barWidth), "%")
		// $(".progress-bar__line-num").text(Math.floor($barWidth) + "%");

		if (number === maxNumber - 1) {
			moneyAnim(".tehnika-quiz-modal .test__side-money", 2000, false)
		} else {
			moneyAnim(".tehnika-quiz-modal .test__side-money", Math.floor(sumMoney), false);
		}
	}
	progress();

	$('.tehnika-quiz-modal input[name="tqw5"]').on('change input', function () {
		$('.tehnika-quiz-modal .gift-block').show();
		$('.tehnika-quiz-modal .gift-block-title').text($(this).val().trim());
		$('.tehnika-quiz-modal #tqw-title').text('Ваш подарок:');
		$('.tehnika-quiz-modal .gift-img').attr({
			"src": $(this).parents('.test_elem').find('img').attr('src'),
		});
		$('.tehnika-quiz-modal .left-block').css({
			'background': `url(/images/${$(this).attr('id')}-big.jpg) no-repeat center`,
			'background-size': 'cover',
		});
	});

	$('.tehnika-quiz-modal input[name="tqw1"]').on('change input', function () {
		if ($(this).is(':checked')) {
			$(`.tehnika-quiz-modal input[name="${$(this).val()}"]`).val(1);
		} else {
			$(`.tehnika-quiz-modal input[name="${$(this).val()}"]`).val(0);
		}
	});

	function btnClick() {

		btnNext.on('click', next);


		$elementRadio.on('input, change', next);
		$('.tehnika-quiz-modal .inp-next').on('input, change', next);

	}
	btnClick();

	function animateTop(eq) {
		let  elem = $('.tehnika-quiz-modal .progress-bar');
		let  top = elem.offset().top - 15;
		$('body,html').animate({ scrollTop: top }, 400);
	}


	function moneyAnim(selector, new_money, simbol) {
		let  numb_start = 0; // Получаем начальное число

		$({ numberValue: numb_start }).animate({ numberValue: new_money }, {

			duration: 560, // Скорость анимации, где 500 = 0,5 одной секунды, то есть 500 миллисекунд
			easing: "linear",

			step: function (val) {

				$(selector).text(Math.ceil(val) + simbol); // Блок, где необходимо сделать анимацию

			}

		});

	}

	function next() {
		event.preventDefault();
		activeSlede[number] = true;
		++number;
		setTimeout(function () {
			$('.tehnika-quiz-modal .test-item').hide();
			$('.tehnika-quiz-modal .test-item').eq(number).fadeIn(1500);
			$('.tehnika-quiz-modal .test__numbers-item').hide();
			$('.tehnika-quiz-modal .test__numbers-item').eq(number).fadeIn(1500);
		}, 300);

		btnActive(!isValid);
		if (testTextNum != 1 && number < (maxNumber - 1)) {
			testTextNum -= 1;
			if (testTextNum < 5 && testTextNum > 1) {
				testText.text(testTextNum + ' вопросa');
			} else if (testTextNum < 2) {
				testText.text(testTextNum + ' вопрос');
			} else {
				testText.text(testTextNum + ' вопросов');
			}
		} else {
			if (number === maxNumber - 1) {
				testText.text('Выберите подарок');
				$('.tehnika-quiz-modal .sbt-js').hide();
				$('.tehnika-quiz-modal .left-block').css({
					"background": 'url(/images/tbg-gift.jpg) no-repeat center',
					'background-size': 'cover',
				});
			}
		}

		if (activeSlede[number] === true) {
			btnNext.prop('disabled', false);
		} else {
			btnNext.prop('disabled', true);
		}

		if (number === 1) {
			$('.tehnika-quiz-modal .test-item').eq(0).find('input').each(function (index, el) {
				if ($(this).prop('checked')) {
					$('.tehnika-quiz-modal .tqw-i-jq').eq(index).show();
				} else {
					$('.tehnika-quiz-modal .tqw-i-jq').eq(index).hide();
				}
			});
		}

		if (number === 1) {
			$('.tehnika-quiz-modal').height(500);
		}
		if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
			if (number === 2 || number === 5) {
				$('.tehnika-quiz-modal').height('100%');
			}
			if (number === 3) {
				$('.tehnika-quiz-modal').height(500);
			}
		}


		if (number === 1) {
			$('.tehnika-quiz-modal .test__main').addClass('tqw3');
			$('.tehnika-quiz-modal .test__main').removeClass('tqw1');
		} else {
			$('.tehnika-quiz-modal .test__main').removeClass('tqw3');
		}
	
		if (number === 5) {
			$('.tehnika-quiz-modal .test__main').addClass('tqwlast');
		} else {
			$('.tehnika-quiz-modal .test__main').removeClass('tqwlast');
		}

		if (number === maxNumber - 1) {
			setTimeout(function () {
				$('.tehnika-quiz-modal .test__btn-block').hide();
				$('.tehnika-quiz-modal .test__numbers').hide();
				$('.tehnika-quiz-modal .test-wrapper').addClass('predfinals');

			}, 300);
		} else if (number === maxNumber + 2) {
			$('.tehnika-quiz-modal .progress-bar').hide();
			$('.tehnika-quiz-modal .test__main').addClass('final');
			$('.tehnika-quiz-modal .test-wrapper').addClass('finaltw');
			$('.tehnika-quiz-modal .left-block').addClass('no-show');
		}

		setTimeout(function () {
			$barWidth += $barLevel;

			progress();
		}, 100);
	}

	$('.tehnika-quiz-modal .test__main form').on('submit', function (e) {
		e.preventDefault();
		if (noValidate($(this))) {
			return
		}
		let  $that = $(this);
		let  fd = new FormData(this);
		let  constr = [];
		let  dopType = [];
		let  plan = [];

		$that.find('.btn').attr({
			'disabled': 'true'
		});

		$('.tehnika-quiz-modal input[name="tqw1"]').each(function (index, el) {
			if ($(this).prop('checked')) {
				constr.push($(this).val().trim());
			}
		});

		$('.tehnika-quiz-modal input[name="tqw5"]').each(function (index, el) {
			if ($(this).prop('checked')) {
				dopType.push($(this).val().trim());
			}
		});

		$('.tehnika-quiz-modal input[name="tqw4"]').each(function (index, el) {
			if ($(this).prop('checked')) {
				dopType.push($(this).val().trim());
			}
		});

		let  constr2 = constr.join(' , ');
		let  dopType2 = dopType.join(' , ');
		let  plan2 = plan.join(' , ');
		fd.append('tqw1', constr2);
		fd.append('tqw4', dopType2);
		fd.append('tqw5', dopType2);

		let  url = $('#tqw7-check').is(':checked') ? 'whatsapp' : 'tehnika';
		new URL(window.location.href).searchParams.forEach(function(value, key) {
			fd.append(key, value);
		});

		$.ajax({
			url: './' + url + '.php',
			type: 'POST',
			contentType: false,
			processData: false,
			data: fd,
			success: function (msg) {
				try {
					yaCounter61923046.reachGoal('TEHNIKA-QUIZ-SENDFORM');
				} catch (e) {
					console.log(e);
				}
				console.log(msg);
				$('.tehnika-quiz-modal .modal-main__subtitle').text('Результат отправим в течение часа!');
				closeModal();
				showModal('thanks-modal');
			},
		});
		if (url === 'whatsapp') {
			fd.append('whatsapp', '1');
			$.ajax({
				url: './tehnika.php',
				type: 'POST',
				contentType: false,
				processData: false,
				data: fd,
			});
		}
	});
}());