$('.callback-bt').addClass('autoflash').append('<div class="flash lighting" style="height: 100px;width: 40px;top: 0px;left: -140px;"></div>');

if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    let footerTop = $('.footer').offset().top - $('.footer').height();
    $(window).scroll(function() {
        if ($(window).scrollTop() && $(window).scrollTop() < footerTop) {
            $('.callback').show();
        } else {
            $('.callback').hide();
        }
    });
}