if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    $(window).scroll(function() {
		toggleMenu();
	});
	
	$(document).on('ready', function(){
		toggleMenu();
	});
}

function toggleMenu() {
	if ($(window).scrollTop() && $(window).scrollTop() + $(window).height() < $('.page').height()) {
		$('.header-fixed').show();
	} else {
		$('.header-fixed').hide();
	}
}

if (new Date().getHours() === 23 || new Date().getHours() < 8) {
	$('.header-call__top').addClass('error').text('Мы сейчас закрыты. Оставьте заявку!');
}