$('.uslugi-list__header-item').on('click', function() {
	$('.uslugi-list__header-item').removeClass('_active');
	$(this).addClass('_active');
	$('.uslugi-list__main').hide();
	$('#uslugi-list-block').removeClass('_mixture');
	$('#uslugi-list-block').removeClass('_brigade');
	$('.uslugi-list__main[type="' + $(this).attr('type') + '"]').show();
	if ($(this).attr('type') === '5') {
		$('#uslugi-list-block').addClass('_mixture');
	}
	if ($(this).attr('type') === '6') {
		$('#uslugi-list-block').addClass('_brigade');
	}
});

setTimeout(function() {
	$('.uslugi-list__main').hide();
	$('.uslugi-list__main[type="1"]').show();
});

var galleryThumbs = new Swiper('._mixture .description__swiper-thumbs', {
	spaceBetween: 10,
	slidesPerView: 3,
	freeMode: true,
	observer: true,
	observeParents: true,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
});
new Swiper('._mixture .description__swiper-top', {
	spaceBetween: 10,
	effect: 'fade',
	observer: true,
	observeParents: true,
	thumbs: {
	  swiper: galleryThumbs
	}
});

var galleryThumbs = new Swiper('._brigade .description__swiper-thumbs', {
	spaceBetween: 10,
	slidesPerView: 3,
	freeMode: true,
	observer: true,
	observeParents: true,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
});
new Swiper('._brigade .description__swiper-top', {
	spaceBetween: 10,
	effect: 'fade',
	observer: true,
	observeParents: true,
	thumbs: {
	  swiper: galleryThumbs
	}
});

$('#brigade').on('submit', function(e) {
    e.preventDefault();
    if (noValidate($(this))) {
        return
	}
	var fd = new FormData(this);
	new URL(window.location.href).searchParams.forEach(function(value, key) {
		fd.append(key, value);
	});
    $.ajax({
        url: './action.php',
        type: 'POST',
        contentType: false,
        processData: false,
        data: fd,
        success: function(){
            try {
              yaCounter61923046.reachGoal('BRIGADE-LANDING-SENDFORM');
            } catch(e) {
              console.log(e);
            }
			showModal('thanks-modal');
        },
	});
});

$('#mixture').on('submit', function(e) {
    e.preventDefault();
    if (noValidate($(this))) {
        return
	}
	var fd = new FormData(this);
	new URL(window.location.href).searchParams.forEach(function(value, key) {
		fd.append(key, value);
	});
    $.ajax({
        url: './action.php',
        type: 'POST',
        contentType: false,
        processData: false,
        data: fd,
        success: function(){
            try {
              yaCounter61923046.reachGoal('MIXTURE-LANDING-SENDFORM');
            } catch(e) {
              console.log(e);
            }
			showModal('thanks-modal');
        },
	});
});