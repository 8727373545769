(function initsnowQuiz() {
	$(".snow-quiz-modal .range-slider-area").slider({
		range: true,
		min: 10,
		max: 1000,
		values: [10, 200],
		slide: function (event, ui) {
			$('.snow-quiz-modal .range-slider-area span:nth-child(3)').attr('value', `${ui.values[1]} м²`);
		},
		create: function () {
			$('.snow-quiz-modal .range-slider-area span:nth-child(3)').attr('value', '200 м²');
		},
	});

	let  number = 0;
	let  maxNumber = $(".snow-quiz-modal .test-item").length - 1;
	let  $element = $(".snow-quiz-modal .test-item").find("input, select, textarea");
	let  $elementRadio = $(".snow-quiz-modal .test-item").not('.final, .no-focus').find("input[type='radio'], input[type='checkbox'] ");
	let  btnNext = $('.snow-quiz-modal .test-next');
	let  isValid;
	let  dataBlock;
	let  activeSlede = [];
	let  testTextNum = maxNumber;
	let  testText = $('.snow-quiz-modal #qw-title');

	for (let  i = 0; i < maxNumber; i++) {
		activeSlede[i] = false;
	}
	testText.text(testTextNum + ' вопросов');
	$element.on('change input', function (e) {
		let  value = $(this).val().trim();

		isValid = value !== "";
		btnActive(!isValid);

	});

	function btnActive(isValid) {
		if (number === 0) {
			// btnPrev.prop('disabled', 'false');
			btnNext.prop('disabled', isValid);
		} else {
			// btnPrev.prop('disabled', false);
			if (activeSlede[number] === true || isValid === false) {
				btnNext.prop('disabled', false);
			} else {
				btnNext.prop('disabled', true);
			}

		}

	}

	// sidebar

	let  $barLevel = 100 / (maxNumber);
	let  $barWidth = 0;
	// money
	let  slideMoney = Math.floor(2000 / (maxNumber + 2));
	let  sumMoney = slideMoney;

	function progress() {
		$(".snow-quiz-modal .progress-bar__line").css({ "width": $barWidth + '%' });

		moneyAnim(".snow-quiz-modal .proc-progres", Math.floor($barWidth), "%")
		// $(".progress-bar__line-num").text(Math.floor($barWidth) + "%");

		if (number === maxNumber - 1) {
			moneyAnim(".snow-quiz-modal .test__side-money", 2000, false)
		} else {
			moneyAnim(".snow-quiz-modal .test__side-money", Math.floor(sumMoney), false);
		}
	}
	progress();

	$('.snow-quiz-modal input[name="sqw6"]').on('change input', function () {
		$('.snow-quiz-modal .gift-block').show();
		$('.snow-quiz-modal .gift-block-title').text($(this).val().trim());
		$('.snow-quiz-modal #qw-title').text('Ваш подарок:');
		$('.snow-quiz-modal .gift-img').attr({
			"src": $(this).parents('.test_elem').find('img').attr('src'),
		});
		$('.snow-quiz-modal .left-block').css({
			'background': `url(/images/${$(this).attr('id')}-big.jpg) no-repeat center`,
			'background-size': 'cover',
		});
	});

	function btnClick() {

		btnNext.on('click', next);


		$elementRadio.on('input, change', next);
		$('.snow-quiz-modal .inp-next').on('input, change', next);

	}
	btnClick();

	function animateTop(eq) {
		let  elem = $('.snow-quiz-modal .progress-bar');
		let  top = elem.offset().top - 15;
		$('body,html').animate({ scrollTop: top }, 400);
	}


	function moneyAnim(selector, new_money, simbol) {
		let  numb_start = 0; // Получаем начальное число

		$({ numberValue: numb_start }).animate({ numberValue: new_money }, {

			duration: 560, // Скорость анимации, где 500 = 0,5 одной секунды, то есть 500 миллисекунд
			easing: "linear",

			step: function (val) {

				$(selector).text(Math.ceil(val) + simbol); // Блок, где необходимо сделать анимацию

			}

		});

	}

	function next() {
		event.preventDefault();
		activeSlede[number] = true;
		++number;
		setTimeout(function () {
			$('.snow-quiz-modal .test-item').hide();
			$('.snow-quiz-modal .test-item').eq(number).fadeIn(1500);
			$('.snow-quiz-modal .test__numbers-item').hide();
			$('.snow-quiz-modal .test__numbers-item').eq(number).fadeIn(1500);
		}, 300);

		btnActive(!isValid);
		if (testTextNum != 1 && number < (maxNumber - 1)) {
			testTextNum -= 1;
			if (testTextNum < 5 && testTextNum > 1) {
				testText.text(testTextNum + ' вопросa');
			} else if (testTextNum < 2) {
				testText.text(testTextNum + ' вопрос');
			} else {
				testText.text(testTextNum + ' вопросов');
			}
		} else {
			if (number === maxNumber - 1) {
				testText.text('Выберите свой подарок');
				$('.snow-quiz-modal .sbt-js').hide();
				$('.snow-quiz-modal .left-block').css({
					"background": 'url(/images/sbg-gift.jpg) no-repeat center',
					'background-size': 'cover',
				});
			}
		}

		if (activeSlede[number] === true || number === 2) {
			btnNext.prop('disabled', false);
		} else {
			btnNext.prop('disabled', true);
		}

		if (number === 1) {
			$('.snow-quiz-modal').height(500);
		}
		if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
			if (number === 5) {
				$('.snow-quiz-modal').height('100%');
			}
		}


		if (number === 1) {
			$('.snow-quiz-modal .test-item').eq(0).find('input').each(function (index, el) {
				if ($(this).prop('checked')) {
					$('.snow-quiz-modal .qw-i-jq').eq(index).show();
				} else {
					$('.snow-quiz-modal .qw-i-jq').eq(index).hide();
				}
			});
		}

		if (number === 2 || number === 1) {
			$('.snow-quiz-modal .test__main').addClass('qw3');
		} else {
			$('.snow-quiz-modal .test__main').removeClass('qw3');
		}

		if (number === maxNumber - 1) {
			setTimeout(function () {
				$('.snow-quiz-modal .test__btn-block').hide();
				$('.snow-quiz-modal .test__numbers').hide();
				$('.snow-quiz-modal .test-wrapper').addClass('predfinals');

			}, 300);
		} else if (number === maxNumber + 2) {
			$('.snow-quiz-modal .progress-bar').hide();
			$('.snow-quiz-modal .test__main').addClass('final');
			$('.snow-quiz-modal .test-wrapper').addClass('finaltw');
			$('.snow-quiz-modal .left-block').addClass('no-show');
		}

		if (number === maxNumber) {
			$('.snow-quiz-modal .test__main').addClass('finals');
		}

		setTimeout(function () {
			$barWidth += $barLevel;

			progress();
		}, 100);
	}

	$('.snow-quiz-modal .test__main form').on('submit', function (e) {
		e.preventDefault();
		if (noValidate($(this))) {
			return
		}
		let  $that = $(this);
		let  fd = new FormData(this);
		let  dopType = [];

		$that.find('.btn').attr({
			'disabled': 'true'
		});

		$('.snow-quiz-modal input[name="sqw4"]').each(function (index, el) {
			if ($(this).prop('checked')) {
				dopType.push($(this).val().trim());
			}
		});


		let  dopType2 = dopType.join(' , ');
		fd.append('sqw4', dopType2);

		let  url = $('#sqw7-check').is(':checked') ? 'whatsapp' : 'snow';
		fd.append('sqw3-1', `${$('.range-slider-area span:nth-child(3)').attr('value')}`);
		new URL(window.location.href).searchParams.forEach(function(value, key) {
			fd.append(key, value);
		});

		$.ajax({
			url: './' + url + '.php',
			type: 'POST',
			contentType: false,
			processData: false,
			data: fd,
			success: function (msg) {
				try {
					yaCounter61923046.reachGoal('SNOW-QUIZ-SENDFORM');
				} catch (e) {
					console.log(e);
				}
				console.log(msg);
				closeModal();
				showModal('thanks-modal');
			},
		});
		if (url === 'whatsapp') {
			fd.append('whatsapp', '1');
			$.ajax({
				url: './snow.php',
				type: 'POST',
				contentType: false,
				processData: false,
				data: fd,
			});
		}
	});
}());