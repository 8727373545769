const names = [];
$('.reviews__swiper .reviews__swiper-item').each(function() {
  	names.push($(this).data("name"));
});

$('.reviews__mobile-pagination-item').on('click', function() {
	$('.reviews__mobile-pagination-item').removeClass('_active');
	$('.reviews .swiper-container').hide();
	$(this).addClass('_active');
	$(`.reviews .reviews__swiper-${$(this).attr('swiper')}`).show();
});

new Swiper('.reviews__swiper', {
	loop: true,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
		type: 'custom',
		renderCustom: function(swiper, current, total) {
			let text = '';
			for (let i = 0; i < total; i++) {
				const active = current === i + 1 ? '_active' : ''
				text += `<span class='swiper-pagination-bullet ${active}'>${names[i]}</span>`;
			}
			return text;
		}
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

new Swiper('.reviews__swiper-1', {
	loop: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

new Swiper('.reviews__swiper-2', {
	loop: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

new Swiper('.reviews__swiper-3', {
	loop: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

setTimeout(function() {
	$('.reviews__swiper-1').hide();
	$('.reviews__swiper-2').hide();
});

$(".colorbox").colorbox();