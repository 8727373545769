$('.overlay, .overlay-bg').on('click', function() {
	$(this).hide();
	$('body').removeClass('modal-open');
	if (quizVar) {
		$(window).scrollTop(quizVar);
		quizVar = 0;
	}
	$('.modal:visible').hide();
});

$('.modal__close').on('click', function() {
	$('.overlay').hide();
	$('.overlay-bg').hide();
	$('body').removeClass('modal-open');
	if (quizVar) {
		$(window).scrollTop(quizVar);
		quizVar = 0;
	}
	$('.modal:visible').hide();
});

var quizVar = 0;

function showModal(modal, desc, type, val) {
	$(`.${modal}`).show();

	if (desc === 'Квиз') {
		$('.overlay-bg').show();
		quizVar = $(window).scrollTop();
		$('body').addClass('modal-open');
	} else {
		$('.overlay').show();
		$(`.${modal} .modal__desc`).html(desc);
		$(`.${modal}`).attr('type', type);
		$(`.${modal} input[name="formname"]`).val(val);
	}
}

function closeModal() {
	$('.overlay').hide();
	$('.overlay-bg').hide();
	$('body').removeClass('modal-open');
	if (quizVar) {
		$(window).scrollTop(quizVar);
		quizVar = 0;
	}
	$('.modal:visible').hide();
}